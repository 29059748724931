@use 'styles' as theme;

.root {
  position: relative;
  z-index: theme.$zHeader;
  background-color: theme.$colorBlue;
}

.container {
  padding-top: theme.size(2);
  padding-bottom: theme.size(2);
}

.logo {
  width: 162px;
  height: 24px;

  @include theme.mediaTabletLandscapeUp {
    width: 231px;
    height: 32px;
  }
}

.label {
  @include theme.textS;

  margin-bottom: -1px;
  color: theme.$colorWhite;
  line-height: 1;

  @include theme.mediaTabletLandscapeUp {
    @include theme.textL;

    line-height: 1;
  }
}
