@use 'styles' as theme;

$mobileWidth: theme.size(40);
$desktopWidth: theme.size(60);

.root {
  width: $mobileWidth;
  flex-shrink: 0;

  @include theme.mediaTabletLandscapeUp {
    width: $desktopWidth;
  }
}

.drawerPaper {
  width: $mobileWidth;
  padding: theme.size(2);

  @include theme.mediaTabletLandscapeUp {
    width: $desktopWidth;
    padding-top: theme.size(6);
  }
}

.close {
  margin-left: auto;
}
