@use 'styles' as theme;

.root {
  color: theme.$colorWhite;
}

.number {
  --color: #{theme.$colorWhite};
  --backgroundColor: transparent;

  @include theme.unstyledButton;
  @include theme.circle(theme.size(3));
  @include theme.flexCenter;

  border: 2px solid theme.$colorWhite;
  background-color: var(--backgroundColor);
  color: var(--color);

  @include theme.mediaTabletLandscapeUp {
    @include theme.circle(theme.size(4.5));
  }
}

.active {
  --backgroundColor: #{theme.$colorWhite};
  --color: #{theme.$colorBlue};
}
