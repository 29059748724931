@use 'styles' as theme;

.root {
  padding: theme.size(8 0);
  background-color: var(--backgroundColor);

  @include theme.mediaDesktopUp {
    padding: theme.size(15 0);
  }
}
