@use 'styles' as theme;

.wrapper {
  display: flex;
  flex: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
}

.mobileSteps {
  @include theme.hideFromTabletLandscape;

  padding: theme.size(2 0);
  background-color: theme.$colorBlue;
}
