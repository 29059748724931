@use 'styles' as theme;

.section {
  @include theme.flexCenter($direction: column);

  flex: 1;
}

.title {
  margin-bottom: theme.size(3);
}
